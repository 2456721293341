<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries" :loading="isDateLoaded">
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
      <template v-slot:add-button>
        <router-link
          to="createChildren"
          append
          class="btn btn-add btn_color_green"
          >Добавить воспитанника</router-link
        >
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      to="children"
      :header="header"
      :source-object="sourceObject"
      :table-data="sourceObject.results"
    />
  </div>
</template>

<script>
import TableFilters from "@/components/tables/TableFilters";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import phoneFilter from "@/helpers/filters/phoneFilter";

export default {
  name: "Children",
  components: { TableLayout, TableFilters },
  data() {
    return {
      loadingBtn: false,
      queries: query.Children.filter(el => el.type !== "children_group"),
      header: [
        // {
        //   text: "№",
        //   value: "id",
        //   sort: true
        // },
        {
          text: "Воспитанник",
          value: "name",
          renderFunction: value => `${value.name}`,
          sort: true
        },
        {
          text: "Табельный номер",
          // renderFunction: value => `${value.teachers[0].phone_number}`
          value: "number",
          sort: true
        },
        {
          text: "Опекуны",
          renderFunction: value => {
            return value?.parents_details
              ?.map(el => {
                if (el.additional_fields) {
                  return (
                    phoneFilter.execute(el.phone_number) +
                    ", " +
                    (() =>
                      el.additional_fields.some(() => 1)
                        ? el.additional_fields[0].value
                        : "-")() +
                    ", " +
                    (() =>
                      el.additional_fields.some(() => 1)
                        ? el.additional_fields[1].value
                        : "-")() +
                    ", " +
                    " (" +
                    `${
                      dictionariesHelper.accountsStatus.userAuth[
                        el.is_phone_confirmed
                      ]
                    }` +
                    ")"
                  );
                }
              })
              ?.join("\n");
          }
        }
        //
        // {
        //   text: "Статус",
        //   renderFunction: value => {
        //     return value?.parents_details
        //       ?.map(el => {
        //         if (el.additional_fields) {
        //           return (
        //             " (" +
        //             `${
        //               dictionariesHelper.accountsStatus.userAuth[
        //                 el.is_phone_confirmed
        //               ]
        //             }` +
        //             ")"
        //           );
        //         }
        //       })
        //       ?.join("\n");
        //   }
        // }
        // {
        //   text: "Опекуны",
        //   renderFunction: value => {
        //     return value?.parents_details
        //       ?.map(el => {
        //         if (el?.actual_accesses && el.full_name) {
        //           return (
        //             el.full_name +
        //             " (" +
        //             `${
        //               //     ]
        //               el.actual_accesses[0]
        //                 ? "Зарегистрирован"
        //                 : "Не зарегистрирован"
        //             }` +
        //             ")"
        //           );
        //         }
        //       })
        //       ?.join("\n");
        //   }
        // }
      ]
    };
  },
  computed: {
    routerParams() {
      return this.$route.params;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    sourceObject() {
      return this.$store.getters.getKindergartensChildren;
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadChildrenUserGroupsList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: { ...this.urlQuery.query, children_group: this.urlQuery.id },
      id: this.urlQuery.id
    });
    this.isDateLoaded = true;
    this.$store
      .dispatch(
        "fetchChildrenByChildrenGroup",
        this.routerParams.kindergartenId
      )
      .finally(() => {
        this.isDateLoaded = false;
      });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query,
            children_group: this.urlQuery.id
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchChildrenByChildrenGroup").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  }
};
</script>

<style scoped></style>
